body {
  margin: 0;
  font-family: Roboto;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  background-color: #f6f6f6;
}

code {
  font-family: 'Roboto';
}

sup {
  color: red;
}

html {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

@media (max-width: 599.95px) {
  body {
    display: block;
  }
}
